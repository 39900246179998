import React from "react";
import './Footer.css';
import { Link } from "react-router-dom";

function Footer() {
    return ( 
        <React.Fragment>
        <footer class="site-footer">
        <div class="container">
            <div class="row">
            <div class="col-sm-12 col-md-6">
                <h6>About</h6>
                <p className="footerText">
                Joseph Levermore, born in Birmingham, United Kingdom, was raised in a family passionate about art and its ability to express an individual's daily experiences. In realising the emotive power of photography, Joseph from a young age, became captivated and thus began his journey.
                In a world bursting with colour, Joseph wishes to challenge his audience by experiencing the world of duality: Black and White. Joseph sees Black and White photography as a medium to inspire individuals to project their own stories to images devoid of colour, but flushing in beauty.
                </p>
            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Categories</h6>
                <ul class="footer-links">
                <li>
                    <Link to="/">
                        London
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        Birmingham
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        Paris
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        Out and about
                    </Link>
                </li>
                <li>
                    <Link to="/">
                        For The Foodies
                    </Link>
                </li>
                </ul>
            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Quick Links</h6>
                <ul class="footer-links">
                <li><Link to="/photoLevs">
                    About
                    </Link></li>
                <li><Link to="/">
                    Work
                    </Link></li>
                <li><a href="/Terms">Terms and Conditions</a></li>
                </ul>
            </div>
            </div>
            <hr />
        </div>
        <div class="container">
            <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
                <p className="footerText">Copyright &copy; 2023 All Rights Reserved by Levermore and Code.
                </p>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
                <ul class="social-icons">
                <li><a class="instagram" href="https://www.instagram.com/photolevs/"><img className='footerLogo' src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fwhite%2Finstagram.png?alt=media&token=e7c33ea8-1894-4cea-b138-c18ea7022da3'
                alt='instagram'
                /></a></li>
                <li><a class="linkedin" href="https://www.linkedin.com/in/josephlevermore/"><img 
                className='footerLogo' 
                src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fwhite%2Flinkedin.png?alt=media&token=60dcad86-b78e-4585-b493-1d71156cc627" alt="linkedin" /></a></li>
                <li><a class="twitter" href="https://twitter.com/photolevs"><img 
                className='footerLogo' 
                src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fwhite%2Ftwitter.png?alt=media&token=e5c98b55-9d51-4e72-b520-83ce752d45f0" alt="twitter" /></a></li>   
                </ul>
            </div>
            </div>
        </div>
    </footer>
        </React.Fragment>
        );
    }
    export default Footer