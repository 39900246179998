import React, { useEffect } from "react";
import "./Header.css";

function Header () {
    // Sticky Menu Area
    useEffect(() => {
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    });
  
    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
      const header = document.querySelector(".header-section");
      const scrollTop = window.scrollY;
      scrollTop >= 250
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    };
    return (
      <>
      <h1 className = "Header">
        <a href = "/" className="navBar">
          photoLevs.
        </a>
      </h1>
        <header className="header-section d-none d-xl-block">
        <ul className = "navBar">
            <li className = "navBar"><a href="/contact" className = "navBarLeft">Contact</a></li>
            <li className = "navBar"><a href="/" className = "navBarLeft">Work</a></li>
            <li className = "navBarRight">
              <a className="navBar" href="/photoLevs">
                photoLevs.
              </a>
            </li>
        </ul>
        </header>
      </>
    );
  };
  
  export default Header;
  