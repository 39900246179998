import React from "react";
import { useState, useEffect } from 'react';
import images from "./images/Images";
import "./TrialWork.css";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Link } from "react-router-dom";

function TrialWork() {
    const [selectedImage, setSelectedImage] = useState(null);
    const [iMG, setIMG] = useState([]);
    const [page, setPage] = useState(1);
    const [shuffledImages, setShuffledImages] = useState([]);

    useEffect(() => {
      const shuffleImages = () => {
        const shuffled = [...images];
        for (let i = shuffled.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        setShuffledImages(shuffled);
      };
    
      shuffleImages();
    }, []);
    
    useEffect(() => {
      const start = (page - 1) * 10;
      const end = start + 10;
      setIMG(shuffledImages.slice(start, end));
    }, [page, shuffledImages]);

    const analytics = getAnalytics();
    function getImageName(image) {
      // Check if the image object has a 'name' property
      if (image) {
        return image.desc;
      } else {
        // If 'name' property is not available, you can return a default value or handle it accordingly
        return 'Unknown Image';
      }
    }
    const openModal = (image) => {
      setSelectedImage(image);
      // Track the event when an image is enlarged
      // console.log(image)
      const imageName = getImageName(image);
      logEvent(analytics, 'image_enlarged', {
        image_name: `image_enlarged_${imageName}`
      });

    };
  
    const closeModal = () => {
      setSelectedImage(null);
    };
  
    const loadMoreImages = () => {
     const start = (page - 1) * 10;
    const end = start + 10;


    if (end >= shuffledImages.length) {
      // Restart the image array by resetting the page to 1
      setIMG(shuffledImages.slice(0, 10));
      setPage(1);
    } else {
      setPage(prevPage => prevPage + 1);
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

    
    return (
      <div className="layout">
        <div className="sidebar">
          <nav>
            <ul className="sideBar">
            <h2 className = "Header">
                <a href = "/" className="sideBar">
                photoLevs.
                </a>
            </h2>
              <li className="sideBar"><Link to='/' className="sideBar">Home</Link></li>
              <li className="sideBar"><Link to='/photoLevs' className="sideBar">About</Link></li>
              <li className="sideBar"><Link to='/contact' className="sideBar">Contact me</Link></li>
              <li className="sideBar"><Link to='/Terms' className="sideBar">Terms</Link></li>
            </ul>
          </nav>
        </div>
  
        <div className="grid">
          {iMG.map(img => (
            <img
              className="desktopImg"
               // Assuming each image has a unique id
              src={img.imageSrc}
              onClick={() => openModal(img)}
              alt={img.caption}
            />
          ))}
          {selectedImage && (
            <div className="desktopModal-overlay">
              <div className="desktopModal-content">
                <button className="desktopModal-close" onClick={closeModal}>
                  &times;
                </button>
                {selectedImage && (
                  <>
                    <img src={selectedImage.imageSrc} alt={selectedImage.caption} className="desktopModal-image" />
                    <div className="desktopModal-caption">{selectedImage.caption}</div>
                    <div className="desktopModal-text">
                      <div className="desktopModal-caption">{selectedImage.desc}</div>
                      {selectedImage.showLink && (
                        <a href={selectedImage.link} target="_blank" rel="noopener noreferrer">
                          please
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <button onClick={loadMoreImages} className="desktopButton">
            Load More
          </button>
        </div>
      </div>
    );
  }
  
  export default TrialWork;