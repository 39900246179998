import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Header, Footer, About, Work, Contact, Terms, NotFound, firebase , TrialWork, DesktopTerms, DesktopAbout, DesktopContact} from './components/common/';


function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the window width is less than a certain size (e.g., 768px)
    const checkScreenWidth = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Event listener for window resize
    window.addEventListener('resize', checkScreenWidth);

    // Initial check on component mount
    checkScreenWidth();

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);



  return (
    <Router>
        {isMobile ? (
        // Render mobile header for smaller screens
        <Header />
      ) : (
        // Render desktop header or Sidebar (SideHeader) for desktop
        <>
        </>
      )}
        <Routes>
          <Route path="/" element={isMobile ? <Work /> : <TrialWork />} />
          <Route path="/photoLevs" element={isMobile ? <About /> : <DesktopAbout />} />
          <Route path="/contact" element={isMobile ? <Contact /> : <DesktopContact />} />
          <Route path="/Terms" element={isMobile ? <Terms /> : <DesktopTerms />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/TrialWork" element={<TrialWork />} />

        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
