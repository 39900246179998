import React from "react";
import './Contact.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import photoLevs from "../work/photoLevs/photoLevs";
import { MetaTags } from 'react-meta-tags';

function DesktopContact() {
    const width = "100%";
    return ( 
        <React.Fragment>
            <MetaTags>
                <title>photoLevs: Contact Information</title>
                <meta name="description" content="Contact Joseph Levermore trading as photoLevs via..." />
                <meta property="og:title" content="photoLevs: Contact Information" />
            </MetaTags>
            <div className="layout">
            <div className="sidebar">
          <nav>
            <ul className="sideBar">
            <h2 className = "Header">
                <a href = "/" className="sideBar">
                photoLevs.
                </a>
            </h2>
              <li className="sideBar"><Link to='/' className="sideBar">Home</Link></li>
              <li className="sideBar"><Link to='/photoLevs' className="sideBar">About</Link></li>
              <li className="sideBar"><Link to='/contact' className="sideBar">Contact me</Link></li>
              <li className="sideBar"><Link to='/Terms' className="sideBar">Terms</Link></li>
            </ul>
          </nav>
        </div>
            <div className="row-info">
                <div className="column">
                <div class="vertical-center">
                    <h2 className = "about">Contact me</h2>
                    <p className = "contact">
                        Email: levermorejoseph@gmail.com
                    </p>
                    <p className = "contact">
                        linkedIn: Joseph Michael Levermore
                    </p>
                    <p className = "contact">
                        Instagram: photolevs
                    </p>
                    <p className = "contact">
                        Twitter: photolevs
                    </p>
                </div>

                </div>
                <div className="column">
                <div class="vertical-center">
                    <div className = "imageContainerContact">
                            <LazyLoadImage
                                src={photoLevs.imageSrc} // use normal <img> attributes as props
                                width = {width}
                            />  
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </React.Fragment>
        );
    }
    export default DesktopContact; 