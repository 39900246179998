import React from "react";
import './notFound.css';

function notFound() {
    return ( 
        <React.Fragment>
            <div className = "page">
                <div >
                    <h2 class="error-404">Page Not Found!</h2>
                    <p class="error-404-p">
                        The page may be temporarily unavailable or may no longer exist. Did you try searching? Enter a keyword(s) in the search field above. Or, here are a few things you can try:
                    </p>
                    <p class="error-404-p">
                        Return to the <a class="error-404-p" href="/">Home Page</a>
                    </p>
                    <p class="error-404-p">
                        Try one of the links below:
                    </p>
                </div>

                <div class="row">
                    <div class="index-error-404-column-01">
                        <div class="row-information-top-navbar">
                            <a class="error-404-p" href='/About'><p>About</p></a>
                            <a class="error-404-p" href='/Work'><p>Work</p></a>

                        </div>
                    </div>
                    <div class="index-error-404-column-01">
                        <div class="row-information-top-navbar">
                            <a class="error-404-p" href='/Contact'><p>Contact me</p></a>
                            <a class="error-404-p" href='/Terms'><p>Terms and Conditions</p></a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        );
    }
    export default notFound