const photoLevs = {
    title: 'photoLevs, Borough, London, United Kingdom',
    subtitle: 'London',
    desc: `photoLevs`,
    imageSrc: 'https://firebasestorage.googleapis.com/v0/b/photolevs-c2e30.appspot.com/o/photoLevs.webp?alt=media&token=ffaff840-a0cc-4fa8-b710-902338845fa5',
    where: {
        location: '', 
    }, 
}

export default photoLevs; 