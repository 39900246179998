// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "@firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "photolevs-c2e30.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, 
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}; 

let debugtoken = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
let sitekey = process.env.REACT_APP_RECAPTACHA_SITE_KEY; 

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const appCheck = (app, {
    provider: new ReCaptchaEnterpriseProvider(sitekey), 
    isTokenAutoRefreshEnabled: true, 
  });

const analytics = getAnalytics(app);
export { app }; 