import React from "react";
import { useState, useEffect } from 'react';
import images from "./images/Images";
import "./Work.css";
import { MetaTags } from 'react-meta-tags';
import { getAnalytics, logEvent } from "firebase/analytics";
import { useInView } from 'react-intersection-observer';


function Work() {

  const analytics = getAnalytics();
    const width = "100%";  
    const [selectedImage, setSelectedImage] = useState(null);
    const [shuffledImages, setShuffledImages] = useState([]);
    const LazyImage = ({ src, alt, onClick, className }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      rootMargin: '0px 0px 0px 0px', // Adjust the rootMargin as needed
    });

    return (
      <div ref={ref} className={`lazy-image ${inView ? 'visible' : ''}`}>
        {inView && <img src={src} alt={alt} onClick={onClick} className={className} />}
      </div>
    );
  };

    useEffect(() => {
        const shuffleImages = () => {
          const shuffled = [...images];
          for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
          }
          setShuffledImages(shuffled);
        };
        //console.log(shuffledImages)
    
        shuffleImages();
      }, []);

    const openModal = (image) => {
      setSelectedImage(image);
      // Track the event when an image is enlarged
      const imageName = getImageName(image);
      logEvent(analytics, 'image_enlarged', {
        image_name: `image_enlarged_${imageName}`
      });
      //console.log(image)
    };

  
    const closeModal = () => {
      setSelectedImage(null);
    };

    function getImageName(image) {
      // Check if the image object has a 'name' property
      if (image) {
        return image.desc;
      } else {
        // If 'name' property is not available, you can return a default value or handle it accordingly
        return 'Unknown Image';
      }
    }
    if (shuffledImages.length === 0) {
        return <div>No images available</div>; // Or display an appropriate message
      }
    return ( 
        <React.Fragment>
            <MetaTags>
                <title>photoLevs</title>
                <meta name="google-site-verification" content="zjAejy2Hjc-qH1XZQfY70bZONP3PZ1WR-z6o1KrB_J4" />
                <meta name="description" content="Work by photoLevs, aka Joseph Levermore. I hope you enjoy...." />
                <meta property="og:title" content="photoLevs" />
            </MetaTags>
            <center>
            <div className="image-gallery">
            {shuffledImages.length === 0 ? (
      <div>No images available</div> // Or display an appropriate message
        ) : (
        shuffledImages.map((image, index) => (
            <div className="image-item" key={index}>
            {image && (
                <img src={image.imageSrc} alt={image.caption} onClick={() => openModal(image)} className="image"/>
            )}
            </div>
        ))
        )}
        {selectedImage && (
        <div className="modal-overlay">
            <div className="modal-content">
            <button className="modal-close" onClick={closeModal}>
                &times;
            </button>
            {selectedImage && (
                <>
                <img src={selectedImage.imageSrc} alt={selectedImage.caption} className="modal-image"/>
                <div className="modal-caption">{selectedImage.caption}</div>
                <div className="modal-text">
                        <div className="modal-caption">{selectedImage.desc}</div>
                        {selectedImage.showLink && (
                        <a href={selectedImage.link} target="_blank" rel="noopener noreferrer">
                            please
                        </a>
                    )}
                    </div>
                </>
            )}
            </div>
        </div>
        )}
    </div>
            </center>       
        </React.Fragment>
        );
    }

    export default Work