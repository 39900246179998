import React from "react";
import './Contact.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';


import photoLevs from "../work/photoLevs/photoLevs";
import { MetaTags } from 'react-meta-tags';

function Contact() {
    const width = "100%";
    return ( 
        <React.Fragment>
            <MetaTags>
                <title>photoLevs: Contact Information</title>
                <meta name="description" content="Contact Joseph Levermore trading as photoLevs via..." />
                <meta property="og:title" content="photoLevs: Contact Information" />
            </MetaTags>
            <h2 className = "about">Contact Information</h2>
            <div className="row-info">
                <div className="column">
                <div class="vertical-center">
                    <h2 className = "about">Calling...</h2>
                    <p className = "contact">
                        Email: levermorejoseph@gmail.com
                    </p>
                    <p className = "contact">
                        linkedIn: Joseph Michael Levermore
                    </p>
                    <p className = "contact">
                        Instagram: photolevs
                    </p>
                </div>

                </div>
                <div className="column">
                <div class="vertical-center">
                    <div className = "imageContainerContact">
                            <LazyLoadImage
                                src={photoLevs.imageSrc} // use normal <img> attributes as props
                                width = {width}
                            />  
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        );
    }
    export default Contact